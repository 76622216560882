"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const components_1 = require("components");
const InputText = ({ input, meta, ...rest }) => {
    const { label = '', placeholder = '', required, requiredIndicator = ' *', labelNote = '', } = rest;
    return ((0, jsx_runtime_1.jsxs)("fieldset", { "data-testid": `fieldset-${input.name}`, className: `${input.className ? input.className : undefined}`, children: [(0, jsx_runtime_1.jsx)("span", { children: (0, jsx_runtime_1.jsxs)(components_1.Label, { size: "medium", "data-testid": `label-${input.name}`, htmlFor: input.name, children: [label, required && ((0, jsx_runtime_1.jsx)(components_1.Span, { variant: "title-medium", className: "required-indicator", "aria-hidden": "true", children: requiredIndicator })), labelNote && ((0, jsx_runtime_1.jsx)(components_1.Span, { variant: "title-medium", className: "label-note", children: `- ${labelNote}` }))] }) }), (0, jsx_runtime_1.jsx)("div", { className: "input-wrapper", children: (0, jsx_runtime_1.jsx)("input", { ...input, ...rest, "data-testid": `input-${input.name}`, type: 'text', placeholder: placeholder }) }), (meta.error || meta.submitError) && meta.touched && ((0, jsx_runtime_1.jsx)(components_1.Span, { variant: "title-medium", className: "error-message", children: meta.error || meta.submitError }))] }));
};
exports.default = InputText;
